import { Controller } from "@hotwired/stimulus"
import Noty from 'noty';

export default class extends Controller {
  static targets = [ "message" ]

  connect() {
    this.notys = this.messageTargets.map(message => {
      return new Noty({
        text: `<span role="alert">${message.dataset.flashText}</div>`,
        type: message.dataset.flashType,
        theme: 'bkpan',
        timeout: 5000,
      })
    });
    this.notys.forEach(n => n.show())
  }

  disconnect() {
    this.notys.forEach(n => n.close())
    // manually remove DOM elements
    // data-turbo-temporary does NOT work
    // turbo:before-cache fires after connecting controller which triggers disconnect and not showing flash
    // happens only in frame promoted navigation (https://github.com/hotwired/turbo/issues/472)
    Array.from(document.querySelectorAll(".noty_layout")).forEach(n => n.remove())
    this.messageTargets.forEach(n => n.remove())
  }
}
